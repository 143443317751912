import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/testprotection/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";
import img1 from "../../images/testprotection/1.jpg";
import img2 from "../../images/testprotection/2.jpg";
import img3 from "../../images/testprotection/3.jpg";
import img4 from "../../images/testprotection/4.jpg";
import img5 from "../../images/testprotection/5.jpg";

const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
];
const TestProtection = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Test & Protection"}
            navSize={"h-96"}
            titleStyles={"xl:mt-12 lg:mt-24 md:mt-24 sm:mt-24 xs:mt-24 mt-20"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Renewable Energy
                </title>
                <meta
                    name="description"
                    content="Maintenance of renewable energy systems and transitioning of conventional prime and standby power to solar. Clean Energy Council accredited."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white py-8"}>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        All job specific ITP’s, check sheets and test sheets will be provided
                        for client approval where required prior to starting any works. Client
                        supplied QA documentation will be utilised where preferred by the
                        client.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        The lists below are headers only and do not name each specific test
                        for each piece of equipment. All tests are implied and will be
                        completed as required by the agreed specific tests in the relevant
                        ITPs and specification.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Each site will be tested to the highest standards and will be fully
                        fit for purpose before energisation.
                    </p>
                    <p className={"mt-10 leading-7 lg:leading-8 lg:text-lg font-exo2 font-bold text-epg-black"}>
                        Each site will be tested to the highest standards and will be fully
                        fit for purpose before energisation.
                    </p>
                    <ol className={"list-decimal p-4 lg:leading-8 lg:text-lg leading-7 font-exo2 text-epg-black"}>
                        <li className={"mb-3"}>
                            Point to point testing including drawing highlighting and drawing
                            signing off for record purposes
                        </li>
                        <li className={"mb-3"}>
                            Tightness of all terminals and wire pull checks
                        </li>
                        <li className={"mb-3"}>
                            Correctness checks of all ferrule markers, labels, installed
                            equipment and equipment ratings.
                        </li>
                        <li className={"mb-3"}>
                            Secondary wiring insulation resistance tests
                        </li>
                        <li className={"mb-3"}>
                            Secondary earthing including single point earthing checks on CT
                            circuits
                        </li>
                        <li className={"mb-3"}>
                            Polarity checks on all AC and DC secondary circuits
                        </li>
                    </ol>
                    <p className={"mt-6 leading-7 lg:leading-8 lg:text-lg font-exo2 font-bold text-epg-black"}>
                        Level Two Testing (Site Acceptance Testing of HV, MV and LV primary
                        plant)
                    </p>
                    <ol className={"list-decimal p-4 lg:leading-8 lg:text-lg leading-7 font-exo2 text-epg-black"}>
                        <li className={"mb-3"}>
                            Current transformer tests including nameplate data collection and
                            comparison with design requirements.
                        </li>
                        <li className={"mb-3"}>
                            Voltage transformer tests including nameplate data collection and
                            comparison with design requirements.
                        </li>
                        <li className={"mb-3"}>
                            Power transformer tests excluding frequency response analysis
                        </li>
                        <li className={"mb-3"}>
                            Single pole and three pole circuit breaker testing
                        </li>
                        <li className={"mb-3"}>Capacitor Bank and reactor testing</li>
                        <li className={"mb-3"}>Filter Bank testing</li>
                        <li className={"mb-3"}>
                            Isolator, earth switch, surge arrestor testing
                        </li>
                        <li className={"mb-3"}>
                            Cable testing (higher voltage VLF testing may require specific test
                            equipment)
                        </li>
                        <li className={"mb-3"}>
                            Contact and joint resistance tests on cable ends, earth grid risers,
                            busbars and circuit breakers
                        </li>
                        <li className={"mb-3"}>Insulation resistance tests</li>
                    </ol>
                    <p className={"mt-6 leading-7 lg:leading-8 lg:text-lg font-exo2 font-bold text-epg-black"}>
                        Level Three Testing (Relay logic, analogue inputs, digital I/O, relay
                        characteristics of all applied settings and relay related SCADA
                        testing)
                    </p>
                    <ol className={"list-decimal lg:leading-8 lg:text-lg p-4 leading-7 font-exo2 text-epg-black"}>
                        <li className={"mb-3"}>
                            Non-directional and directional overcurrent, earth fault and
                            sensitive earth fault
                        </li>
                        <li className={"mb-3"}>
                            High and low impedance restricted earth fault
                        </li>
                        <li className={"mb-3"}>Transformer protection</li>
                        <li className={"mb-3"}>Generator protection</li>
                        <li className={"mb-3"}>Cable/line differential protection</li>
                        <li className={"mb-3"}>Busbar protection schemes</li>
                        <li className={"mb-3"}>Distance protection and schemes</li>
                        <li className={"mb-3"}>
                            Auto reclose and distance to fault location
                        </li>
                        <li className={"mb-3"}>
                            All settings compared to latest settings report
                        </li>
                    </ol>
                    <p className={"mt-6 leading-7 lg:leading-8 lg:text-lg font-exo2 font-bold text-epg-black"}>
                        Level Four Testing (Complete integration testing of tested equipment
                        to ensure fit for purpose plant)
                    </p>
                    <ol className={"list-decimal lg:leading-8 lg:text-lg p-4 leading-7 font-exo2 text-epg-black"}>
                        <li className={"mb-3"}>
                            Primary current injection to check relay displays including CT
                            summation checks
                        </li>
                        <li className={"mb-3"}>
                            Primary voltage injection to check relay displays and live
                            indicators
                        </li>
                        <li className={"mb-3"}>
                            Complete tests to check entire SCADA points list
                        </li>
                        <li className={"mb-3"}>
                            Integration of relay binary inputs and outputs
                        </li>
                        <li className={"mb-3"}>
                            Compete tripping checks including circuit breaker fail and trip
                            circuit supervision
                        </li>
                        <li className={"mb-3"}>
                            Complete closing checks including close circuit supervision
                        </li>
                        <li className={"mb-3"}>Heater and fan checks</li>
                        <li className={"mb-3"}>
                            Primary operating current checks on busbar protection schemes
                        </li>
                        <li className={"mb-3"}>
                            All mechanical and electrical interlocking schemes
                        </li>
                        <li className={"mb-3"}>
                            LV automatic changeover systems including the integration of the
                            local standby generator
                        </li>
                    </ol>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default TestProtection;
